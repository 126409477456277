<template>
    <v-footer id="home-footer" color="grey darken-4" dark min-height="40">
        <v-row>
            <v-col cols="12" md="6">
                <div
                    class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
                >
                    <template v-for="(s, i) in footerLinks">
                        <a
                            v-if="s.url"
                            :key="i"
                            class="white--text pa-1 pa-md-0"
                            :href="`${s.url}`"
                            v-text="s.title"
                        />
                        <router-link
                            v-else-if="s.path"
                            :key="s.title"
                            :to="s.path"
                            class="pa-1 pa-md-0"
                        >
                            <a :key="i" class="white--text" v-text="s.title" />
                        </router-link>

                        <v-responsive
                            v-if="i < footerLinks.length - 1"
                            :key="`divider-${s.title}`"
                            class="mx-4 shrink hidden-sm-and-down"
                            max-height="24"
                        >
                            <v-divider vertical />
                        </v-responsive>
                    </template>
                </div>
            </v-col>

            <v-col
                class="text-center text-md-right footer-text"
                cols="12"
                md="6"
            >
                Copyright &copy; {{ new Date().getFullYear() }} Getac Technology
                Corp. and its Subsidiaries. All rights reserved
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: `HomeFooter`,

    data: () => ({
        getacRoot: `https://getac.com/us/`
    }),
    computed: {
        footerLinks() {
            return [
                {
                    title: `Privacy Policy`,
                    url: `${this.getacRoot}privacy-policy`
                },
                {
                    title: `Cookie Policy`,
                    url: `${this.getacRoot}cookie-policy`
                },
                { title: `Terms of Use`, url: `${this.getacRoot}terms-of-use` },
                {
                    title: `Help & Support`,
                    path: `/help-support`
                },
                {
                    title: `Recycle Your Unit`,
                    path: `/recycle-unit`
                },
                {
                    title: 'Recycle Your Battery',
                    path: '/recycle-battery'
                }
            ]
        }
    }
}
</script>
<style scoped>
#home-footer,
.footer-text {
    font-size: 10px;
}
</style>
<style lang="sass">
#home-footer a
  text-decoration: none
#home-footer
  font-size: .95em
</style>
